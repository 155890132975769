import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, IconButton,Button} from '@mui/material';
import { Search as SearchIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import axios from 'axios';
import { ContextData } from '../../../context/Context';
import baseUrl from '../../../BaseURL'; 
import AddUserDialog from '../Contents/AdminUser/AddAdminUserDialog';
import SetPaymentRegisterForm from '../Contents/SetPayment/SetPaymentRegisterForm';
/* import EditStudentPop from './PopDialogue/Student/EditStudentPop';
 */

const SetPayment = () => {

  
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [StudentData,setStudentData] = useState(null)
  const [CountData,setCountData] = useState(0)
  const rowsPerPage = 6;
  const {setLoader,accesstoken,RefreshUesFF} = useContext(ContextData)

  useEffect(()=>{
      

      async function FetchData(){
              //  setLoader(true)  

            try {
              const response = await axios.get(`${baseUrl}/auth/get_set_payment`,{
                withCredentials: true,
                headers: {
                  'Accept' : 'application/json',
                  'authorization': `Bearer ${accesstoken !== null && accesstoken}`
                }}) 

              setCountData(Object.keys(response.data))
              setStudentData(response.data)
              setLoader(false)
             
            } catch (err) {
               if(err) console.log(err.message)
              // setLoader(false)
            }

           
      }FetchData()  


  },[RefreshUesFF])

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };


  
    const List =  Object.keys(StudentData !== null && StudentData).map(data=> StudentData[data])
    const filteredUsers = List.filter(user => {
    const lowerCaseSearchText = searchText.toLowerCase().trim();
    return (
       
        user.registration_type.toLowerCase().includes(lowerCaseSearchText)
      // Add more fields for search if needed (e.g., state, phone number)
    );
    
  });

  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);
  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const usersToShow = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);  
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };

  const HanldeRemove = async (sttokens) =>{

    
    try {
      const response = await axios.delete(`${""}/auth/delete_student/${sttokens}`,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${accesstoken !== null && accesstoken}`
        }})
          } catch (err) {

       if(err) console.log(err.message)
      
       }



  }

  return (
    <div  className="con-dev" style={{width:"100%",height:"100%"}}>
    <Container
    >
      <Typography variant="h4" gutterBottom className='flex justify-between items-center'>
        <p className='font-extrabold mt-3'>Set Payment</p>
        <div>
          <SetPaymentRegisterForm />
        </div>
      </Typography>
      
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        fullWidth
        margin="normal"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{background:"rgb(0, 24, 69)"}}>
            <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>No</TableCell>
              <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Registration Type</TableCell>
              <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Registration Fee</TableCell>
              <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Time</TableCell>
              <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Date</TableCell>
              <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersToShow.map((user,index) => (
                    
              <TableRow key={user.id}>
                 <TableCell align='center' sx={{whiteSpace:"nowrap"}}>{index + 1}</TableCell>
           
                <TableCell align='center' sx={{whiteSpace:"nowrap"}}>{user.registration_type}</TableCell>
                <TableCell align='center' sx={{whiteSpace:"nowrap"}}>{user.fee}</TableCell>
               

                <TableCell align='center' sx={{whiteSpace:"nowrap"}}>{user.createdAt.slice(user.createdAt.indexOf("T") + 1)}</TableCell>
                <TableCell align='center' sx={{whiteSpace:"nowrap"}}>{user.createdAt.slice(0,user.createdAt.indexOf("T"))}</TableCell>
    
                  <TableCell align='center'>
                <Button className='Hover-' onClick={()=> HanldeRemove(user.amount_token)} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(199, 1, 1)', margin:'9px 0px'}}  size="large" variant="contained">
                   Remove
                 </Button>

                  </TableCell> 
                  
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <div>
          {currentPage > 1 && (
      <Button className='Hover-' onClick={handlePrevPage} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(0, 24, 69)', color:"white", margin:'9px 4px'}}  size="large" variant="contained">
      <ArrowBackIcon />
      </Button>
          )}
          <span>Page {currentPage} of {totalPages}</span>
          {currentPage < totalPages && (
             <Button className='Hover-' onClick={handleNextPage} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(0, 24, 69)', color:"white", margin:'9px 4px'}}  size="large" variant="contained">
                 <ArrowForwardIcon />
           </Button>
            
          )}
          
        </div>
      )}
    </Container>
    </div>
  );
};

export default SetPayment;


// get_data_adminuser