import React, { useContext, useEffect, useState } from 'react';
import CardIDNum from './CardIDNum';
import baseUrl from "../../../../BaseURL";
import { ContextData } from '../../../../context/Context';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';

export default function PreCardShow(props) {


  const {Refresh,setRefresh, ID_ref_Num,setID_ref_Num ,accesstoken,issuance,setissuance,Dep_and_post,setDep_and_post} = useContext(ContextData);

  const {
    fname,
    lname,
    gender,
    age,
    position,
    department,
    stateoforigin,
    picture,
    r_ID,
    id_ref_num
  } = props.profileData;
  const [doneBTN,setdoneBTN] = useState(true) 

 
   const HandleIDNum = (e)=>{
    setID_ref_Num(e.target.value)
}


   const HandleIDIssue = (e)=>{
        setissuance((prevData) => ({...prevData,[e.target.name]:e.target.value})) 
   }


   const HandleDep_Post =  (e)=>{

    setDep_and_post((prevData) => ({...prevData,[e.target.name]:e.target.value}))
}


useEffect(()=>{

   setDep_and_post((prev) =>({...prev,department:department}))
   setDep_and_post((prev) =>({...prev,position:position}))
   setID_ref_Num("")

},[props.profileData])




const ProcessDoneIDCard = async () => {


     if(doneBTN === true) return toast.warn("You are not done with ID card while Reference number is empty, please fill the Reference number and click on update button")


    
  const fetchProfileData = async () => {
   
    try {
      const response = await axios.put(`${baseUrl}/auth/approve_id_card/${r_ID}`, {
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${accesstoken !== null && accesstoken}`
        }
      });
      

       if(response.data.status === true){

         toast.success(response.data.statusText);
        
           
         setRefresh(!Refresh)
       
       }else{

         toast.success(response.data.statusText);

        }
      
 

    } catch (error) {
      
      toast.warn("Error fetching profile data");
    }
  };

  fetchProfileData();



}



const HandleUpdateInputs = (type)=>{


  if(type === "dept"){


    const data = {
      value: Dep_and_post.department,
      r_ID,
      type:'dept'
                 }
     
 
 axios.put(`${baseUrl}/auth/update_card_inputs`,data,{
   withCredentials: true,
   headers: {
     'Accept' : 'application/json',
     'authorization': `Bearer ${accesstoken !== null && accesstoken}`
 
   }}).then(res=>{
             
    toast.success(res.data.statusText)  
                
   }).catch(err=> {

       if(err) return toast.warn(err.message)
          
   })


      
  }else if(type === "post"){ 


        
    const data = {
      value: Dep_and_post.position,
      r_ID,
      type:'post'
                 }
     
 
 axios.put(`${baseUrl}/auth/update_card_inputs`,data,{
   withCredentials: true,
   headers: {
     'Accept' : 'application/json',
     'authorization': `Bearer ${accesstoken !== null && accesstoken}`
 
   }}).then(res=>{
          
    toast.success(res.data.statusText)  
                
   }).catch(err=> {

       if(err) return toast.warn(err.message)
          
   })

  }else if(type === "idRefnum"){
 
    const data = {
      value : ID_ref_Num,
      r_ID,
      type:'idRefnum'
 }
     
 
 axios.put(`${baseUrl}/auth/update_card_inputs`,data,{
   withCredentials: true,
   headers: {
     'Accept' : 'application/json',
     'authorization': `Bearer ${accesstoken !== null && accesstoken}`
 
   }}).then(res=>{

       
        toast.success(res.data.statusText)  
        setdoneBTN(false)
             
   }).catch(err=> {
    setdoneBTN(false)
       if(err) return toast.warn(err.message)
          
   })

  
  }










}




  return (
    <div style={{
      width: '400px',
      height: '580px',
      border: '1px solid #000',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      position: 'relative'
    }} className='hide-at-prnt'>
      <div style={{ textAlign: 'center' }}>

        <Button  onClick={()=>ProcessDoneIDCard()} className='hide-at-prnt Hover- absolute left-1 pl-4 pr-4 pt-2 pb-2 shadow-lg rounded-md bg-green-950 cursor-pointer'   
         style={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:"green"  ,color:"white", margin:'9px 4px',position:"absolute"  , left:5}}  >
            Done
         </Button>
 
        <img
          src={`${baseUrl}/files/assets/MemberPictures/${picture}`}
          alt='Member-pic'
          style={{
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            position: 'absolute',
            top: '20px',
            right: '20px',
          }}
        />
      </div>

      <div style={{ marginTop: '120px' }}>
        <p style={{ fontSize: '1.5em', fontWeight: 'bold', marginBottom: '0.5em' }}>
          {fname} {lname}
        </p>
        <p style={{ margin: '0.5em 0' }}>
          <strong>Gender:</strong> {gender}
        </p>
        <p style={{ margin: '0.5em 0' }}>
          <strong>Age:</strong> {age}
        </p>
        <p style={{ margin: '0.5em 0' }}>
          <strong>Position:</strong> <input className='mb-1' style={{width:"45%",textTransform:"capitalize",color: Dep_and_post.position.length > 10 ?  "red" : "" }} onChange={HandleDep_Post} name='position' value={Dep_and_post.position}/> <Button onClick={()=> HandleUpdateInputs("post")}>update</Button>
        </p>
        <p style={{ margin: '0.5em 0' }}>
          <strong>Department:</strong> <input className='mb-1' style={{width:"45%",textTransform:"capitalize"}} onChange={HandleDep_Post} name='department' value={Dep_and_post.department}/><Button onClick={()=> HandleUpdateInputs("dept")}>update</Button>
        </p>
        
        <p style={{ margin: '0.5em 0' }}>
          <strong>State of Origin:</strong> {stateoforigin}
        </p>
      </div>

      <p style={{
        position: 'absolute',
        bottom: '10px',
        fontSize: '1.2em',
        fontWeight: 'bold',
        textAlign: 'left',
        width: '100%',
        color: 'black',
        whiteSpace: 'nowrap'
      }}>
        ID NO: FIN/<input style={{width:"45%",textTransform:"uppercase"}} onChange={HandleIDNum} value={ID_ref_Num}/>{r_ID}
        <p><Button onClick={()=> HandleUpdateInputs("idRefnum")}>update</Button> </p>
      </p>
      <p className='mb-1'><label className='font-bold'>State of Issue: </label><input className='mb-1' style={{width:"45%",textTransform:"uppercase"}} onChange={HandleIDIssue} name='state' value={issuance.state}/> {/* <Button>update</Button> */} </p>
      <p className='mb-1'><label className='font-bold'>Date of Issue: </label><input className='mb-1' style={{width:"45%",textTransform:"uppercase"}} onChange={HandleIDIssue} name='issuDate' value={issuance.issuDate}/> {/* <Button>update</Button> */} </p>
      <p className='mb-1'><label className='font-bold'>Expiry Date: </label><input className='mb-1' style={{width:"45%",textTransform:"uppercase"}} onChange={HandleIDIssue} name='expiry' value={issuance.expiry}/> {/* <Button>update</Button> */} </p>
       {id_ref_num && 
        <p><label className='font-bold'>ID NO: FIN / </label>  {id_ref_num}{r_ID} </p>
       }
     
      
    </div>


  );
  
  
}