import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Avatar, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, IconButton,Button} from '@mui/material';
import { Search as SearchIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import axios from 'axios';
import { ContextData } from '../../../context/Context';
import baseUrl from '../../../BaseURL'; 
import AddMemberDialog from '../Contents/Member/AddMemberDialog';
import ViewPopUserEdit from '../Contents/Member/ViewPopUserEdit';
import LoadingStatus from './LoadingStatus';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import VerifiedIcon from '@mui/icons-material/Verified';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import PopUpDeposite from '../Contents/Deposite/PopUpDeposite';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

/* import EditStudentPop from './PopDialogue/Student/EditStudentPop';
 */

const Members = () => {


  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [MemberData,setMemberData] = useState(null)
  const [CountData,setCountData] = useState(0)
  const [Amount,setAmount] = useState("")
  const Navigate = useNavigate()

 

  const rowsPerPage = 6;
   const {setaccesstoken,setUserToken,setLoader,accesstoken,Refresh,setRefresh,Person,RefreshAmount,} = useContext(ContextData)

   useEffect(()=>{setRefresh(!Refresh)},[])
   
  useEffect(()=>{
      

       if( Person !== null){
          if( Person.acc_level === "100"){
            
            async function FetchData(){
              //  setLoader(true)  
            try {
              const response = await axios.get(`${baseUrl}/auth/get_member_datas_for_admin`,{
                withCredentials: true,
                headers: { 
                  'Accept' : 'application/json',
                  'authorization': `Bearer ${accesstoken !== null && accesstoken}`
                }}) 
        
              setCountData(Object.keys(response.data))
              setMemberData(response.data)
              setLoader(false)
             
            } catch (err) {
               if(err) console.log(err.message)
              // setLoader(false)
            }
    
           
      }FetchData()

          }else{
         
            async function FetchData(){
                    //  setLoader(true)  
      
                  try {
                    const response = await axios.get(`${baseUrl}/auth/get_data_member_user/${Person.token}`,{
                      withCredentials: true,
                      headers: {
                        'Accept' : 'application/json',
                        'authorization': `Bearer ${accesstoken !== null && accesstoken}`
                      }}) 
              
                    setCountData(Object.keys(response.data))
                    setMemberData(response.data)
                    setLoader(false)
                   
                  } catch (err) {
                     if(err) console.log(err.message)
                    // setLoader(false)
                  }
         
            }FetchData()
          }
       }


      

  },[Refresh])

  useEffect(()=>{

          // Fetches data from amount 
          async function FetchAmountData(){
            //  setLoader(true)  
    
          try {
            const response = await axios.get(`${baseUrl}/auth/get_amount/${Person.token}`,{
              withCredentials: true,
              headers: {
                'Accept' : 'application/json',
                'authorization': `Bearer ${accesstoken !== null && accesstoken}`
              }}) 
      
            
              setAmount(response.data[0].amount)
           
           
          } catch (err) {
             if(err) console.log(err.message)
            // setLoader(false)
          }
    
         
    }FetchAmountData()
    

  },[Refresh])


  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  
    const List =  Object.keys(MemberData !== null && MemberData).map(data=> MemberData[data])
    const filteredUsers = List.filter(user => {
    const lowerCaseSearchText = searchText.toLowerCase().trim();
    let fullName = user.fname +" "+ user.lname
    return (
       
     ( fullName.toLowerCase().includes(lowerCaseSearchText) ) ||
      user.mobile.toLowerCase().includes(lowerCaseSearchText) ||
      user.country.toLowerCase().includes(lowerCaseSearchText) ||
      user.origin.toLowerCase().includes(lowerCaseSearchText) 
    //  user.email.toLowerCase().includes(lowerCaseSearchText) 
      // Add more fields for search if needed (e.g., state, phone number)
    );
  });
  
  const filteredMembers = useMemo(() => {
    return MemberData ? MemberData.filter(list => list.acc_status === 2 || list.acc_status === 1 || list.acc_status === 3) : [];
  }, [MemberData]);

  const filteredPendingMembers = useMemo(() => {
    return MemberData ? MemberData.filter(list => list.acc_status === 0) : [];
  }, [MemberData]);

  const count = filteredMembers.length;
  const PendingMembers = filteredPendingMembers.length

  
  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);
  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const usersToShow = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };

  const HanldeRemove = async (tokens) =>{

    
    try {
      const response = await axios.delete(`${baseUrl}/auth/remove_member/${tokens}`,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${accesstoken !== null && accesstoken}`

        }})  

       } catch (err) {
       if(err) console.log(err.message)
     //  setLoader(false)
       }



  }

  const RefreshPage = () =>{
    setRefresh(!Refresh)
  }
   const Logout = () =>{
  
            // Fetch Administrator Refresh Token
    async function FetchData(){
  
      try {
      const response = await axios.get(`${baseUrl}/free/logout`,{
          withCredentials: true,
          headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
              }
             })  
            
                toast.success("logout")
                setaccesstoken(null)
                setUserToken(null)
                localStorage.clear()
                Navigate("/login") 
  
    
      } catch (err) {
      if(err) console.log(err.message)
        toast.warn(err.message)
      }
      }
      FetchData() 
  
  
  
      }

  return (

    <Container>
      <div  className='flex justify-between items-center  mem-top-point-con'>

         {
          Person !== null && <>{Person.acc_level === "0" && 
         <div className='flex w-full justify-between hide-refrsh-logout-btns-con'>
         <Button className='Hover-'  elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(0, 24, 69)', color:"white", margin:'9px 4px'}}  size="medium" variant="contained">
           Refresh Page
         </Button>

         <Button className='Hover-'  elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(0, 24, 69)', color:"white", margin:'9px 4px'}}  size="medium" variant="contained">
           Logout
         </Button>

         </div>
          }</>
         }
        <p className='font-extrabold mt-3'>Registered Members</p>
       
        {
           Person !== null && <>{Person.acc_level === "100" ?

            <p><b>Total Submitted:</b> {count}</p> 
            :
            <p><b>Total Submitted:</b> {PendingMembers}</p>
            }</>
        }
        
          {
            Person !== null && Person.usertype === "user" &&
             <>
             {Amount !== null && 
             <p className='mt-3 border-spacing-2 text-4xl mb-2'> <span className='bg-slate-900  text-white p-1 rounded-md'><b>Credit:</b><span className='bg-white border-spacing-x-1 text-black whitespace-nowrap pl-1 pr-1' >{Amount}</span></span> </p>
              }
             
            <div className='add-btn-con-mmem'>

              {
                PendingMembers !== null && <>{PendingMembers >=1 ? 
                
                
                  <PopUpDeposite
                  countForms = {count}
                  PendingMembers = {PendingMembers}
                 />
                
                :
             
                <Button disabled className='Hover- btn-add-mem' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'green', margin:'9px 0px'}}  size="large" variant="contained">
                Deposite
              </Button>}</>
              }
           
             
              <AddMemberDialog />

            </div>


             </>
            }

      </div>
      
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        fullWidth
        margin="normal"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>

            <TableRow sx={{background:"rgb(0, 24, 69)"}}>
              <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>No.</TableCell>
               
             
              {
                Person !== null && <> {Person.acc_level === "100" &&  
                <>
                 <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Avatar</TableCell>
                </>}
                </>
                }
                
              <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Full Name</TableCell>
                {
                  Person !== null && <> {Person.acc_level === "100" && 
                 <>
                 
                 <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Age</TableCell>
                  <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Mobile</TableCell>
                  <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Origin</TableCell>
                  <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Country</TableCell>
                  {Person !== null && <> {Person.acc_level === "100" && 
                   <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Restrict</TableCell>
                  
                  } </> 
                  }
               
                  <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>NIN</TableCell>
                  <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Verified</TableCell>
                  <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Received IDCard</TableCell>
                 
                 </>
               
                  } </>
                }

              {Person !== null && <TableCell align='center' sx={{color:"white",fontWeight:"800"}}> {Person.acc_level === "100" ? "View/Edit" : "View"} </TableCell>
              
              }
              
              {Person !== null && <> {Person.acc_level === "0" && 
               <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Status</TableCell>
              
              } </> 
              
              }

              {Person !== null && <> {Person.acc_level === "100" && 
              <TableCell align='center' sx={{color:"white",fontWeight:"800"}}>Remove Member</TableCell>} </> 
              
              }
              
            </TableRow>
          </TableHead>
          <TableBody>
            {
              MemberData !== null && <>
              
            {usersToShow.map((user,index) => (
              <TableRow key={user.id}>
                <TableCell  align='center' sx={{whiteSpace:"nowrap"}}>{index + 1}</TableCell>

                    {
                      Person !== null && <> {Person.acc_level === "100" &&  <> 
                         <TableCell>
                          
                          <Avatar sx={{boxShadow:'2px 2px 10px rgba(0,0,0,0.364)'}} alt={user.fname} src={`${baseUrl}/files/assets/MemberPictures/${user.picture}`} />
                        
                          </TableCell>
                        </> } </>
                    }

                <TableCell  align='center' sx={{whiteSpace:"nowrap"}}>{user.fname} {user.lname}</TableCell>
                {
                  Person !== null && <> {Person.acc_level === "100" && 
                                <> 
                                
                 <TableCell  align='center' sx={{whiteSpace:"nowrap"}}>{user.age}</TableCell>
                <TableCell  align='center' sx={{whiteSpace:"nowrap"}}>{user.mobile}</TableCell>
                <TableCell  align='center' sx={{whiteSpace:"nowrap"}}>{user.origin}</TableCell>
                <TableCell  align='center' sx={{whiteSpace:"nowrap"}}>{user.country}</TableCell>
                                </>
                 } </>
                }
              
                {Person !== null && <> {Person.acc_level === "100" && 

               <TableCell align='center' sx={{whiteSpace:"nowrap"}}>{user.acc_lock.toString() === "0" ?
                
               <Button className='Hover-' onClick={()=> HanldeRemove(user.st_tokens)} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(199, 1, 1)', margin:'9px 0px'}}  size="large" variant="contained">
                  Lock
                </Button>
                           :
                <Button className='Hover-' onClick={()=> HanldeRemove(user.st_tokens)} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(179, 103, 21)', margin:'9px 0px'}}  size="large" variant="contained">
                  Unlock
               </Button>
               }
 
               </TableCell>

              
              } </> 
              
              }

                {
                Person !== null && <> {Person.acc_level === "100" &&  
                <>
                <TableCell  align='center' sx={{whiteSpace:"nowrap"}}>{user.nin}</TableCell>
                <TableCell  align='center' sx={{whiteSpace:"nowrap"}}>{user.acc_status ? 'Yes' : 'No'}</TableCell>
                <TableCell  align='center' sx={{whiteSpace:"nowrap"}}>{user.id_card_status === "0" ? 'No' : 'Yes'}</TableCell>

                </>}
                </>
                }
                
                  <TableCell>
                   <ViewPopUserEdit
                    token={user.token}
                    pic={user.picture}
                    nin={user.ninFile}
                    ninId={user.nin}
                    r_ID={user.r_ID}
                   
                   /> 
                  </TableCell>
 
                  <TableCell>
               {Person !== null && <> {Person.acc_level === "100" && 
              <Button className='Hover-' onClick={()=> HanldeRemove(user.token)} elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(199, 1, 1)', margin:'9px 0px'}}  size="large" variant="contained">
              Remove
            </Button>
            } </> 

              
               }
              
                {
                  Person !== null &&
                  <>
                  {Person.acc_level === "0" &&
                  
                   <>
                 
                   {user.acc_status ===  0 &&
                   
                   <TableCell  align='center' sx={{whiteSpace:"nowrap",display:"flex",justifyContent:"center",alignItems:"center"}}><b className='mr-1 cursor-wait'> Pending</b> <LoadingStatus color={"secondary"} /> </TableCell>
                   }
                    
                   {user.acc_status ===  1 &&
                   
                   <TableCell  align='center' sx={{whiteSpace:"nowrap",display:"flex",justifyContent:"center",alignItems:"center"}}><b className='mr-1 text-purple-950 cursor-progress'> Verifiying</b> <HowToRegIcon color={"secondary"} />... </TableCell>
                   }

                  {user.acc_status ===  3 &&
                   <TableCell  align='center' sx={{whiteSpace:"nowrap",display:"flex",justifyContent:"center",alignItems:"center"}}><b className='mr-1 text-green-950'> Approved</b> <VerifiedIcon color={"success"} /> </TableCell>
                   }
                     {(user.acc_status !==  3 && user.acc_status !== 0 && user.acc_status !== 1 && user.acc_status !== 3)  &&
                   <TableCell  align='center' sx={{whiteSpace:"nowrap",display:"flex",justifyContent:"center",alignItems:"center"}}><b className='mr-1 text-green-950'> Processing...</b>  </TableCell>
                   }
                  {user.acc_status ===  4 &&
                   <TableCell  align='center' sx={{whiteSpace:"nowrap",display:"flex",justifyContent:"center",alignItems:"center"}}><b className='mr-1 text-red-400 '> Invalid info</b> <NoAccountsIcon color={"warning"} /> </TableCell>
                   }
                   

                    </>



                  }

                  </>
                }
               
                  </TableCell>
                  
              </TableRow>
            ))}
              </>
            }
            {
              MemberData === null && <p className=' w-full h-full flex justify-center items-center'>loading...</p>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <div>
          {currentPage > 1 && (
            <IconButton onClick={handlePrevPage}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <span>Page {currentPage} of {totalPages}</span>
          {currentPage < totalPages && (
            <IconButton onClick={handleNextPage}>
              <ArrowForwardIcon />
            </IconButton>
          )}
        </div>
      )}
    </Container>
  );
};

export default Members;
