import React, { useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { ContextData } from '../../../../context/Context';
import PersonIcon from '@mui/icons-material/Person';
import {
  Typography
} from "@mui/material";
import baseUrl from "../../../../BaseURL";
import { toast } from "react-toastify";
import CardIDNum from './CardIDNum';
import PreCardShow from './PreCardShow';



const IDCardShow = (props) => {


  const [profileData, setProfileData] = useState(null);
  const {accesstoken,Person,Refresh,setRefresh,issuance,Dep_and_post} = React.useContext(ContextData) 
  const [error, setError] = useState(null);


  const HandleApprove = async (token) =>{

    
    try {
      const response = await axios.put(`${baseUrl}/auth/Verified`,{token},{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${accesstoken !== null && accesstoken}`
        }}).then(res=>{
               toast.success(res.data)
               setRefresh(!Refresh)
        }) 

       } catch (err) {
       if(err) console.log(err.message)
     //  setLoader(false)
       }

  }


  useEffect(() => {

    const fetchProfileData = async () => {
      setProfileData("get")
      try {
        const response = await axios.get(`${baseUrl}/auth/get_member_data_pageID_card/${props.token}`, {
          withCredentials: true,
          headers: {
            'Accept' : 'application/json',
            'authorization': `Bearer ${accesstoken !== null && accesstoken}`
          }
        });
        setProfileData(response.data[0]);
      } catch (error) {
        setError("Error fetching profile data");
      }
    };

    fetchProfileData();



  },[props.token,Refresh]);

  if (error) {
    return <Typography variant="h6">{error}</Typography>;
  }

  if (!profileData) {
      if(props.vr === "vr"){
        return <Typography variant="h6">Click name to load the profile</Typography>;  
      }else{
        return <Typography variant="h6">Select a member</Typography>;
      }
   
  }

  const {
    fname,
    lname,
    email,
    mobile,
    picture,
    origin,
    country,
    stateoforigin,
    stateofresident,
    other_state,
    token,
    dateofbirth,
    age,
    nin,
    profession,
    position,
    other_profession,
    registerer_token,
    gender,
    r_ID,
    m_status
  } = profileData;



  return (
    <>
     {
      profileData === "get" ?  <div> <p>Loading new data</p> </div> :
      <> 
      
 <div id='con-id-show-con-inner' style={{display:"none"}} className='w-3/4  h-40 relative con-id-show-con-inner'>

<p style={{top:"10.1em",left:"34.9em",fontSize:"1.7vw"}} className='absolute font-bold'>{gender}</p>
   <p style={{top:"12.4em",left:"23.2em",whiteSpace:"nowrap",fontSize:"1.7vw"}} className='absolute font-bold'>{fname} {lname}</p>
   <p style={{top:"14.8em",left:Dep_and_post.position.length > 10 ?  "20.6em" : "26.6em" ,whiteSpace:"nowrap",fontSize:"1.7vw",textTransform:"capitalize"}} className='absolute font-bold'>{Dep_and_post.position}</p>
   <p style={{top:"16.8em",left:"26.6em",whiteSpace:"nowrap",fontSize:"1.7vw"}} className='absolute font-bold'>{age}</p>
   <p style={{top:"18.8em",left:"26.6em",whiteSpace:"nowrap",fontSize:"1.7vw",textTransform:"capitalize"}} className='absolute font-bold'>{Dep_and_post.department}</p>
   <p style={{top:"20.8em",left:"26.6em",whiteSpace:"nowrap",fontSize:"1.7vw",textTransform:"capitalize"}} className='absolute font-bold'>{stateoforigin}</p>

   <p style={{top:"23.6em",left:"36em",fontSize:"1.7vw",whiteSpace:"nowrap",textTransform:"capitalize"}} className='absolute font-bold'>{issuance.state}</p>
   <p style={{top:"24.8em",left:"36em",fontSize:"1.7vw",whiteSpace:"nowrap",textTransform:"capitalize"}} className='absolute font-bold'>{issuance.issuDate}</p>
   <p style={{top:"26.2em",left:"36em",fontSize:"1.7vw",whiteSpace:"nowrap",textTransform:"capitalize"}} className='absolute font-bold'>{issuance.expiry}</p>

   <CardIDNum r_ID={r_ID} />
   
 <img src={`${baseUrl}/files/assets/MemberPictures/${picture}`} alt='Member -pic' style={{top:"115px",left:"2.2em",width:"150px",height:"150px"}} className='absolute font-bold rounded-full '/>

  <img src='/assets/idcardpic.png' />

</div>
<PreCardShow
profileData = {profileData}
/>
      </>
     }
   
    </>
  );
};

export default IDCardShow;


// get_data_adminuser