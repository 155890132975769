import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {  useLocation, useNavigate } from 'react-router-dom';
import { ContextData } from '../../context/Context'
import BadgeMax from '../Section/pages/BadgeMax';
import baseUrl from "../../BaseURL";
import axios from 'axios';


export default function Nav() {

      const Navigate = useNavigate();
      const UseLocation = useLocation()
      const {Person,accesstoken,Refresh} = React.useContext(ContextData)
      const [StudentData,setStudentData] = React.useState()
    

        React.useEffect(()=>{


          async function FetchData(){
          try {
            const response = await axios.get(`${baseUrl}/auth/get_member_datas_for_all`,{
              withCredentials: true,
              headers: { 
                'Accept' : 'application/json',
                'authorization': `Bearer ${accesstoken !== null && accesstoken}`
              }}) 
            setStudentData(response.data)
                 
          } catch (err) {
             if(err) console.log(err.message)
          }
    }FetchData()

        },[Refresh])

        
        const filteredVrify = React.useMemo(() => {
          return StudentData ? StudentData.filter(list => list.acc_status === 1) : [];
        }, [StudentData,Refresh]);

        const filteredApprove = React.useMemo(() => {
          return StudentData ? StudentData.filter(list => list.acc_status === 2) : [];
        }, [StudentData,Refresh]);

        const filteredPending = React.useMemo(() => {
          return StudentData ? StudentData.filter(list => list.acc_status === 0) : [];
        }, [StudentData,Refresh]);

        const filteredPendingIDCard = React.useMemo(() => {
          return StudentData ? StudentData.filter(list => list.acc_status === 3 && list.id_card_status === "0") : [];
        }, [StudentData,Refresh]);
    
      const countVfy = filteredVrify.length;
      const countApprove = filteredApprove.length;
      const countPending = filteredPending.length;
      const countPendingIDCard = filteredPendingIDCard.length;


      const isActive = (pathName) =>{
        return UseLocation.pathname === pathName    
      }



  return (
    <div  className='navList ml-5 mr-5'>
     
   
     {
          Person !== null && 
          
          <>
           {
            Person.acc_level === "100" &&
             <>
            <Button style={{background:isActive("/dashboard/statistics") && "rgba(36, 4, 49, 0.9)" }} onClick={() => Navigate("/dashboard/statistics")} variant="contained">Statistics</Button>
           <Button style={{background:isActive("/dashboard/payment") && "rgba(36, 4, 49, 0.9)" }} onClick={() => Navigate("/dashboard/payment")} variant="contained">Set Payment</Button>
          
    
          <Button 
           onClick={() => Navigate("/dashboard/adminusers")} 
           style={{background:isActive("/dashboard/adminusers") && "rgba(36, 4, 49, 0.9)" }}
           variant="contained"
           >Admin Users</Button>
          <Button style={{background:isActive("/dashboard/user") && "rgba(36, 4, 49, 0.9)" }} onClick={() => Navigate("/dashboard/user")} variant="contained">Users</Button>
          
             </>
           }

           {
             Person.acc_level === "100" &&
             <> 
             <Button style={{background:isActive("/dashboard/members") && "rgba(36, 4, 49, 0.9)" }} onClick={() => Navigate("/dashboard/members")} variant="contained">Members</Button>
             <Button style={{background:isActive("/dashboard/verification") && "rgba(36, 4, 49, 0.9)" }} onClick={() => Navigate("/dashboard/verification")} variant="contained">
             <span className='absolute z-10 top-0 '> <BadgeMax value={countVfy}  /></span>
             Verification</Button> 
             <Button style={{background:isActive("/dashboard/approve") && "rgba(36, 4, 49, 0.9)" }} onClick={() => Navigate("/dashboard/approve")} variant="contained">Approve 
             <span className='absolute z-10 top-0 '> <BadgeMax value={countApprove}  /></span>
             </Button> 

             <Button style={{background:isActive("/dashboard/pending") && "rgba(36, 4, 49, 0.9)" }} onClick={() => Navigate("/dashboard/pending")} variant="contained">Pending 
             <span className='absolute z-10 top-0 '> <BadgeMax value={countPending}  /></span>
             </Button> 

             <Button style={{background:isActive("/dashboard/id_card") && "rgba(36, 4, 49, 0.9)" }} onClick={() => Navigate("/dashboard/id_card")} variant="contained">ID-Card 
             <span className='absolute z-10 top-0 '> <BadgeMax value={countPendingIDCard}  /></span>
             </Button> 
            

             </>
           }

            {
             Person.acc_level === "0" &&
              <> 
             <Button style={{background:isActive("/dashboard/members") && "rgba(36, 4, 49, 0.9)" }} onClick={() => Navigate("/dashboard/members")} variant="contained">Members</Button>
            {/*  <Button style={{background:isActive("/dashboard/deposite") && "rgba(36, 4, 49, 0.9)" }} onClick={() => Navigate("/dashboard/payment")} variant="contained">Deposit</Button> */}
             
             </>
            }
         
          </>
            }


                  {
            Person !== null && <>   
             {
              Person.usertype === "ID-Card-User" &&
               <> 
               <Button style={{background:isActive("/dashboard/statistics") && "rgba(36, 4, 49, 0.9)" }} onClick={() => Navigate("/dashboard/statistics")} variant="contained">Statistics</Button>
              </>
             }</>
            }

         {   /* Finance Department */ }
            {
            Person !== null && <>   
             {
              Person.usertype === "Finance Manager" &&
               <> 
               <Button style={{background:isActive("/dashboard/statistics") && "rgba(36, 4, 49, 0.9)" }} onClick={() => Navigate("/dashboard/statistics")} variant="contained">Statistics</Button>
              </>
             }</>
            }


            {/* Reviewer  analyzer */}

            {
            Person !== null && <>   
             {
              Person.usertype === "Finance Manager" &&
               <> 
               <Button style={{background:isActive("/dashboard/statistics") && "rgba(36, 4, 49, 0.9)" }} onClick={() => Navigate("/dashboard/statistics")} variant="contained">Analytics</Button>
              </>
             }</>
            }

    </div>
  );
}