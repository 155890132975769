import axios from 'axios'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import baseUrl from '../BaseURL';


export const ContextData = createContext()
      
function Context({children}) {


    const navigate = useNavigate();



    const [accesstoken,setaccesstoken] = useState(null)
    const [LoaderPercent,setLoaderPercent] = useState()
    const [Refresh,setRefresh] = useState(false)
    const [RefreshAmount,setRefreshAmount] = useState(false)
    const [Person,setPerson] = useState(null)
    const [UserToken,setUserToken] = useState("")
    const [compressedImage,setCompressedImage] = React.useState("");
    const [compressedNINImage,setCompressedNINImage] = React.useState("");
    const [SortValue,setSortValue] = useState(0)
    const [ID_ref_Num,setID_ref_Num] = useState("")
    const [MemberDataIDCard,setMemberDataIDCard] = useState(null)
   /*  const [issuance,setissuance] = useState({
        state:"Kaduna",
        issuDate:"10-08-2024",
        expiry:"10-08-2028"
    }) */
  
    const [issuance, setissuance] = useState(() => {
      const currentDate = new Date(); // Get current date
      const expiryDate = new Date(currentDate);
      expiryDate.setFullYear(currentDate.getFullYear() + 4); // Add 4 years to current date
      
      return {
          state: "Kaduna",
          issuDate: currentDate.toISOString().split("T")[0], // Format to YYYY-MM-DD
          expiry: expiryDate.toISOString().split("T")[0], // Format to YYYY-MM-DD
      };
  });

    const [Dep_and_post,setDep_and_post] = useState({
         department:"",
         position: ""
    })



  // Fetch client Refresh Token

/*    useEffect(()=>{
   
    // Fetch client Refresh Token

    async function FetchData(){

    try {
    const response = await axios.get(`${baseUrl}/client/clientrefreshtoken`,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'Content-Type': 'application/json',
            }
           }) 
              
         if(!response.data.status){
        //  navigate('/login', { replace: true }); 
         // setPersonKeys(null) 
            return
         }
         if(response.data.status) {

          setPersonKeys(response.data.accesstoken)
          setUserToken(response.data.Usertoken)
         }  

    } catch (err) {
    if(err) console.log(err.message)
    }
    }

    FetchData()

},[])
 */

// Get Client Data

useEffect(()=>{


  async function FetchData(){
      
      try {
          
        const response = await axios.get(`${baseUrl}/auth/get_single_data_user/${UserToken}`,{
          withCredentials: true,
           headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            'authorization': `Bearer ${accesstoken}`
          }
          } 
          ) 
     
         setPerson(response.data[0])  
       // console.log(Person)
     } catch (err) {
       if(err) console.log(err.message)
     }
  
    }
    
    FetchData() 

 },[UserToken,accesstoken])
 

// Fetch Administrator Refresh Token

 useEffect(()=>{
   
  // Fetch Administrator Refresh Token
  async function FetchData(){

  try {
  const response = await axios.get(`${baseUrl}/free/refreshtokens`,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',
          }
         })  
        
      // if(!response.data.status) return  setAdministratorTokens(null)
           
       if(response.data.status){
        setaccesstoken(response.data.accesstoken)
        setUserToken(response.data.token)
      //  console.log(response.data.accesstoken)
       }

  } catch (err) {
  if(err) console.log(err.message)
  }
  }
  FetchData()

},[UserToken,accesstoken])
 





  return (
    <ContextData.Provider
    value={{
      accesstoken,setaccesstoken,
      LoaderPercent,setLoaderPercent,
      Refresh,setRefresh,
      UserToken,setUserToken,
      Person,setPerson,
      compressedImage,setCompressedImage,
      compressedNINImage,setCompressedNINImage,
      RefreshAmount,setRefreshAmount,
      SortValue,setSortValue,
      ID_ref_Num,setID_ref_Num,
      issuance,setissuance,
      Dep_and_post,setDep_and_post,
      MemberDataIDCard,setMemberDataIDCard
      
    } }
    > 
    {children}
    
    </ContextData.Provider>
  )
}

export default Context