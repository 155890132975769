import React, { useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { Button} from '@mui/material';
import { ContextData } from '../../../context/Context';
import baseUrl from '../../../BaseURL'; 
import MemberDataPage from '../Contents/Member/MemberDataPage';
import PersonIcon from '@mui/icons-material/Person';
import IDCardShow from '../Contents/IDCard/IDCardShow';
/* import EditStudentPop from './PopDialogue/Student/EditStudentPop';
 */

const IDCard = () => {

  

  
  const [CountData,setCountData] = useState(null)
  const [Token,setToken] = useState(null)
  const {setLoader,accesstoken,RefreshUesFF,Person,Refresh,setRefresh,MemberDataIDCard,setMemberDataIDCard} = useContext(ContextData)


  useEffect(()=>{setRefresh(!Refresh)},[])

    useEffect(() =>{


      async function FetchData(){
        //  setLoader(true)  

      try {
        const response = await axios.get(`${baseUrl}/auth/get_data_member_user/${Person.acc_level}`,{
          withCredentials: true,
          headers: {
            'Accept' : 'application/json',
            'authorization': `Bearer ${accesstoken !== null && accesstoken}`
          }}) 
  
     
        setMemberDataIDCard(response.data)
         
        setCountData(response.data.length)
        setLoader(false)
       
      } catch (err) {
         if(err) console.log(err.message)
        // setLoader(false)
      }

     
}FetchData()
     
      

      
    },[Refresh])

    const filteredMembers = useMemo(() => {
      return MemberDataIDCard ? MemberDataIDCard.filter(list => list.id_card_status  === "0") : [];
    }, [MemberDataIDCard,Refresh]);

    const count = filteredMembers.length;

    const GetTokens = (token)=>{
       
      setToken(token)
     // alert(token)
     // console.log(Token)

    }

    const PrintFunc = ()=>{

          window.print()
    }


  return (
    <div  className="con-de" style={{width:"100%",height:"100%"}}>
   
     {/*  */}
      
     
         <div className=' w-full h-full flex '>


          <div className='aside-id-show w-80 h-full flex flex-col justify-center items-center '>
                 <h2 className='bg-blue-950 w-full text-3xl text-white text-center font-extrabold h-10 mb-4'>Names</h2>
                 
              <div style={{height:"50rem"}}  className='overflow-y-auto w-full'>

                    

                    {
                       MemberDataIDCard === null  ? 
                       <p className='text-center font-extrabold'>Loading List...</p>

                       :

                    <ul className='cursor-pointer v-ul-list-p'>
                      {MemberDataIDCard !== null && MemberDataIDCard
                      .filter(list => list.acc_status === 3 && list.id_card_status === "0")
                      .map((list, index) => (
                      <li 
                      key={index}
                      onClick={() => GetTokens(list.token)}
                      className="border-b-2 border-t-0 border-b-slate-100 border-t-slate-950 pt-3 pb-3 mt-2 pl-1 plr-1"
                      style={{backgroundColor: list.token === Token && "green",color:list.token === Token && "white"}}
                      >
                      <PersonIcon sx={{fontSize: "2em"}} />
                      <span>{list.fname} {list.lname}</span>
                      </li>
                      ))}
                    </ul> 
                    }

              </div>
               
          </div>






          <div className='bg-slate-200 w-full h-full'>

          <h1 className='text-center text-3xl hide-at-prnt'>  <p className='font-extrabold  uppercase'>ID Card</p></h1>
             <p className='pl-3 hide-at-prnt'><b>Total submitted</b>: {count}</p>
             <div className='w-full flex justify-center items-center'>
             <button onClick={PrintFunc} className='hide-at-prnt Hover- pl-4 pr-4 pt-2 pb-2 shadow-lg rounded-md cursor-pointer'   style={{whiteSpace: 'nowrap' , fontSize: '14px', backgroundColor:'rgb(0, 24, 69)', color:"white", margin:'9px 4px'}}  >
             Print
         </button>
             
       
             
             
             </div> 
              <div id='con-id-show-con' style={{height:"50rem"}} className=' w-full overflow-y-auto flex justify-center mt-6'>
             {/* <MemberDataPage token={Token !== null && Token} vr="vr"  /> */}

                  <IDCardShow
                   token={Token !== null && Token} 
                  />
              </div>

         </div>







         </div>
      
   
    </div>
  );
};

export default IDCard;


// get_data_adminuser